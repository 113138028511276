import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import CTA from '../lib/cta'
import BlockContent from './block-content'
import ContentBlock from './modules/ContentBlock'
import Container from './container'
import styled from '@emotion/styled'
import theme from '../styles/theme'
import { MaxWidthContainer, fluid, cta } from '../styles/mixins'
import _ from 'lodash'

function Project (props) {
  const { 
		_rawBody,
		title,
		mainImage,
		publishedAt,
		publishingInfo,
		otherProjects,
		_rawExcerpt,
		_rawSubheader
	} = props


  return (
    <Article>
      <Container>
			<h2 id="book-title">
				<div className="title">{title}</div>
				{_rawSubheader && 
					<div className="subheader">
						<BlockContent blocks={_rawSubheader || []} />
					</div>
				}
			</h2>
        <MaxWidthContainer maxW="1100px"> 
						<div className="flexbox">
							{mainImage && mainImage.asset && (
								<div className="left">
									<img
										src={imageUrlFor(buildImageObj(mainImage))
											.width(800)
											.height(Math.floor((16 / 11.5) * 800))
											.fit('crop')
											.url()}
										alt={mainImage.alt}
									/>
								</div>
							)}
							<div className="right">
								<div className="body-excerpt">
            			{_rawExcerpt && <ContentBlock content={_rawExcerpt || []} />}
								</div>
								<div className="body-content">
            			{_rawBody && <ContentBlock content={_rawBody || []} />}
									{props.publishingInfo && <div className="publishing-info">{props.publishingInfo}</div> }
								</div>
								{props.cta && <CTA data={props.cta} /> }
							</div>
						</div>
						<div>
							{publishedAt && (
								<div>
									{differenceInDays(new Date(publishedAt), new Date()) > 3
										? distanceInWords(new Date(publishedAt), new Date())
										: format(new Date(publishedAt), 'MMMM Do YYYY')}
								</div>
							)}
						</div>
					</MaxWidthContainer>
					<MaxWidthContainer>
						{otherProjects && 
								<section id='other-projects'>
									<h3>Other Books</h3>
									<ul>
										{otherProjects.map((project, i) => {
											const slug = _.get(project, 'slug.current')
											return (
												<li key={`other_${i}`}>
													<Link to={`/books/${slug}`}>
													{project.mainImage && project.mainImage.asset && (
															<img
																src={imageUrlFor(buildImageObj(project.mainImage))
																	.width(200)
																	.height(Math.floor((16 / 11.5) * 200))
																	.fit('crop')
																	.url()}
																alt={project.mainImage.alt}
															/>
													)}
													</Link>
												</li>
											)
										})}
									</ul>
								</section>
							}
				</MaxWidthContainer>
      </Container>
    </Article>
  )
}

export default Project

const Article = styled.article`

	background:#fcfcfc;
	>div {
		display: flex;
    flex-directioN: column;
    justify-content: space-between;
    min-height: calc(100vh - 290px);
		margin-bottom:50px;
	}
	#book-title {
		padding: 30px;
		text-align: center;
		background-color: ${theme.colors.green};
		margin:10px 0 50px;
		.title {
			color: white;
			font-family:${theme.fonts.bold};
			text-transform: uppercase;
			${fluid('font-size', 28, 32, 600, 1200)}
			margin-bottom:10px;
		}
		.subheader {
			text-transform:none;
			${fluid('font-size', 18, 24, 600, 1200)}
			font-family:Times, Serif;
			color: white;
		}
	}
	.publishing-info {
		${fluid('font-size', 13, 14, 600, 1200)}
		color:${theme.colors.brown};
	}
	.flexbox {
		display:flex;
		align-items:center;

		.left {
			width:35%;
			padding-right:5%;
			img {
				width:100%;
				display:inline-block;
				border:solid 3px ${theme.colors.mud}; 
			}
		}
		.right {
			width:60%;
			
			
			.body-excerpt {
				${fluid('font-size', 26, 32, 600, 1200)}
				blockquote {
					font-weight:bold;
					margin-bottom:10px;
				}
			}
			.body-content {
				margin-top:30px;
				${fluid('font-size', 18, 24, 600, 1200)}
			}
			
			.cta {
				${cta}
				margin-top:20px;
			}
		}
		
	}
	#other-projects {
		h3 {
			margin-top:50px;
			margin-bottom:20px;
			color:${theme.colors.green};
			font-family:${theme.fonts.bold};
			text-transform:uppercase;
			${fluid('font-size', 18, 24, 600, 1200)}
		}
		ul {
			display:flex;
			justify-content:space-between;
			list-style:none;
			li {
				padding-right:20px;
				&:last-child {
					padding-right:0;
				}
				img {
					max-width:100%;
				}
			}
		}
	}
	@media (${theme.breakpoints.medium})  {
			#other-projects {
				ul {
					flex-wrap:wrap;
					li {
						width:18%;
						padding-right:0;
						margin-bottom:20px;
					}
				}
			}
		}
		@media (${theme.breakpoints.mobile})  {
			.flexbox {
				flex-direction:column;
				.left, .right {
					width:100%;
				}
				.right {
					margin-top:30px;
				}
				.left {
					padding-right:5px;
				}
			}
			#other-projects {
				ul {
					li {
						width:48%;
					}
				}
			}
		}
`