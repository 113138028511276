import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import { mapEdgesToNodes } from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Project from '../components/project'
import Hero from '../components/modules/Hero'
import { MaxWidthContainer } from '../styles/mixins'
import ContentBlock from '../components/modules/ContentBlock'

import theme from '../styles/theme'
import styled from '@emotion/styled'

export const query = graphql`
	fragment SanityImage on SanityMainImage {
		crop {
			_key
			_type
			top
			bottom
			left
			right
		}
		hotspot {
			_key
			_type
			x
			y
			height
			width
		}
		asset {
			_id
		}
		alt
	}	
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
			featuredProject {
				id
				mainImage {
					...SanityImage
				}
				title
				slug {
					current
				}
				title
				_rawBody
				_rawExcerpt
				_rawSubheader
				publishingInfo
				cta {
					title
					target
					href
				}
			}
    }
    page: sanityPage(_id: { regex: "/(drafts.|)home/" }) {
      header
      _rawBody
			_rawExtendedContent
      _id
			mainImage {
        ...SanityImage
      }
			cta {
				title
				href
				target
			}
    }
		otherProjects: allSanityProject(sort: { fields: [sortOrder], order: ASC }) {
			edges {
				node {
					mainImage {
						...SanityImage
					}
					slug {
						current
					}
					id
				}
			}
		}
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const page = (data || {}).page
	const otherProjects = (data || {}).otherProjects
	const featuredProject = site.featuredProject

	function filterOutCurrentId(x) {
		console.log("X: ", x)
		return x.id !== featuredProject.id
	}
	const theOtherProjects = mapEdgesToNodes(otherProjects).filter(filterOutCurrentId)
	console.log("otherProjects:", theOtherProjects)

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <Hero page={page} heroLink='about'/>
				{featuredProject && <FeaturedProjectContainer><Project {...featuredProject} otherProjects={theOtherProjects}/></FeaturedProjectContainer>}
				<MaxWidthContainer maxW="1100px">
					{page._rawExtendedContent && <ContentBlock content={page._rawExtendedContent} />}
				</MaxWidthContainer>
			</Container>
    </Layout>
  )
}

export default IndexPage

const FeaturedProjectContainer = styled.section`
	#book-title {
		background-color:${theme.colors.mud} !important;
	}
`