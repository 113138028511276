import React from 'react'
import styled from '@emotion/styled'
import BlockContent from '../block-content'
import theme from '../../styles/theme'
import { fluid } from '../../styles/mixins'
function ContentBlock(props){
	const { content } = props
	return (
		<ContentBlockContainer>
			{content.length > 0 && <BlockContent blocks={content || []} />}
		</ContentBlockContainer>
	)
}

export default ContentBlock

const ContentBlockContainer = styled.div`
	color: ${theme.colors.green};
	font-family: serif;

	a {
		font-weight:bold;
		text-decoration:italic;
		color:${theme.colors.brown};
		&:hover {
			${theme.colors.gold};
		}
	}
	em {
		font-style:italic !important;
	}
	h1, h2, h3 {
		${fluid('font-size', 32, 44, 600, 1200)}
		margin-top:30px;
	}
	p {
		margin:32px 0;
		${fluid('font-size', 18, 24, 600, 1200)}
	}
	blockquote {
		${fluid('font-size', 18, 24, 600, 1200)}
		margin-bottom:24px;
		margin-top:24px;
	}
	h4 {
		${fluid('font-size', 10, 14, 600, 1200)}
		font-family:arial;
		text-align:right;
		width:75%;
		padding-left:25%;
	}
	strong {
		font-weight:bold;
	}
`