import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import theme from '../../styles/theme'
import BlockContent from '../block-content'
import { 
	MaxWidthContainer,
	headerStyle,
	pStyle,
	listStyle,
	fluid,
	cta
} from '../../styles/mixins'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import CTA from '../../lib/cta'


const Hero = ({ page, basic, align, smallheight, heroLink }) => {
  const { header, _rawBody, mainImage, cta } = page

	console.log("CTA: ", cta);

let heroImage = null
	if(mainImage && mainImage.asset) {
		heroImage = imageUrlFor(buildImageObj(mainImage))
									.width(1200)
									.height(Math.floor((9 / 16) * 1200))
									.fit('crop')
									.url()
	}

	console.log("hero Image: ", heroImage)
  return (
    <OuterHeroContainer
      basic={basic}
      smallheight={smallheight}
      align={align}
			bkgImg={heroImage}
    >
      <MaxWidthContainer className="max-width-container">
        <div className="content-container">
					{header && <h1>{header}</h1>}
					{_rawBody && <BlockContent blocks={_rawBody || []} />}
					{cta && cta.href && <p><CTA data={cta} /></p>}
				</div>
      </MaxWidthContainer>
    </OuterHeroContainer>
  )
}

export default Hero

const OuterHeroContainer = styled.section`
	overflow:hidden;
	min-height:calc(60vh - 100px);
	display:flex;
	width:100%;
	margin:${props => props.smallheight ? '20px 0' : '0'};
	background-image:url(${props => props.bkgImg ? props.bkgImg : null});
	background-size:cover;
	background-position:top right;
	.max-width-container {
		margin-bottom:50px;
		margin-top:50px;
		display:flex;
		flex-direction:column;
		justify-content:center;
		text-align:${props => props.basic && props.align !== "center" ? 'left' : 'center'};
		.content-container {
			max-width:700px;
			padding:20px;
			background-color:rgba(0,102,0,0.9);
			h1 {
				${headerStyle}
			}
			p {
					${pStyle}
					color: ${props => (!props.basic ? theme.colors.white : theme.colors.ocean)};
			}
			ul {
					${listStyle}
					color: ${props => (!props.basic ? theme.colors.white : theme.colors.ocean)};
			}
			.cta {
				${cta}
			}
		}
	}
`
